'use client';

import * as React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import { X as XIcon } from '@phosphor-icons/react/dist/ssr/X';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import axios from 'axios';
import Paper from '@mui/material/Paper';
import dayjs from 'dayjs';

export interface ConfirmationDialogProps {
    onClose?: () => void;
    open?: boolean;
    amount?: number;
    discount?: string;
    onConfirming?: () => void;
    selectedDate?: dayjs.Dayjs;
    endDate?: dayjs.Dayjs;
    backgroundImageURL?: string;
    who?: string;
}

export function ConfirmationDialog({ onClose, open = false, onConfirming, amount, discount, selectedDate, endDate, backgroundImageURL, who}: ConfirmationDialogProps): React.JSX.Element {
    const [netTerms, setNetTerms] = React.useState<string>(' 30');
    const [maxDiscount, setMaxDiscount] = React.useState<string>('3');
    const [dailyDiscountRate, setDailyDiscountRate] = React.useState<string>('0.2');
    const [discountFor10Days, setDiscountFor10Days] = React.useState<string>('2');
    const [isSuccess, setIsSuccess] = React.useState(false);
    const [offersSent, setOffersSent] = React.useState(0);

    if (amount === undefined ) {
        amount = 0;
    }
    if (discount === undefined) {
        discount = '0';
    }
    const handleSetAndSend = async (amount: number) => {
        // Here you would typically send the offer data to your backend
        // For now, we'll just simulate a successful send
        // listCampaigns();
        // sendTestEmail();
        // getPing();
        // getLastJournalEntry();
        setOffersSent(amount);
        setIsSuccess(true);
    };

    const handleSendAnotherOffer = () => {
        setIsSuccess(false);
    };

    const handleGoToOffersTracking = () => {
        onClose?.();
    };

    return (
        <Dialog PaperProps={{
            sx: {
              borderRadius: '10px',
              width: { xs: '90%', sm: '500px' },
              maxWidth: '500px',
              p: { xs: 2, sm: 3 },
            },
          }} onClose={onClose} open={open}>
            {!isSuccess ? (
                <>
                    <Stack direction="row" spacing={3} sx={{ alignItems: 'center', justifyContent: 'space-between', px: { xs: 3, sm: 3 }, py: { xs: 2, sm: 3 } }}>
                        <Typography variant="h6" sx={{ fontSize: { xs: 16 } }}>Confirm Offer</Typography>
                        <IconButton onClick={onClose}>
                            <XIcon />
                        </IconButton>
                    </Stack>
                    <DialogContent>
                        <Typography variant="body2" sx={{ mb: { xs: 3, sm: 5 }, fontSize: { xs: 14, sm: 16 } }}>
                        Awesome! 🎉 By confirming, you're all set to accept this great offer. Let’s make it happen!
                        </Typography>
                        <Stack spacing={2}>
                            <Stack direction="row" spacing={2} mt={2}>
                                <Paper elevation={0} sx={{ 
                                background: `url(${backgroundImageURL}) no-repeat center center`,
                                backgroundSize: 'cover',
                                p: 2,
                                flex: 2,
                                color: 'white' 
                                }}>
                                    {who === 'vendor' ? (
                                        <>
                                            <Typography variant="body2" sx={{ fontSize: { xs: '0.75rem', sm: '0.875rem' } }}>You get your payment</Typography>
                                            <Typography variant="h4" fontWeight="bold" sx={{ fontSize: { xs: '1rem', sm: '1.5rem' } }}>{Math.floor(dayjs(endDate).diff(selectedDate, 'day'))} days earlier</Typography>
                                        </>
                                    ) : (
                                        <>
                                            <Typography variant="body2" sx={{ fontSize: { xs: '0.75rem', sm: '0.875rem' } }}>Reduce your costs by</Typography>
                                            <Typography variant="h4" fontWeight="bold" sx={{ fontSize: { xs: '1rem', sm: '1.5rem' } }}>${((amount * parseFloat(discount) / 100)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Typography>
                                        </>
                                    )}
                                </Paper>
                                <Paper elevation={0} sx={{ bgcolor: '#f5f5f5', p: 2, flex: 1, alignItems: 'center' }}>
                                    {who === 'vendor' ? (
                                        <>
                                            <Typography variant="body2" sx={{ fontSize: { xs: '0.75rem', sm: '0.875rem' } }}>Discount</Typography>
                                            <Typography variant="h6" fontWeight="bold" sx={{ fontSize: { xs: '1rem', sm: '1.25rem' } }}>{discount}%</Typography>
                                        </>
                                    ) : (
                                        <>
                                            <Typography variant="body2" sx={{ fontSize: { xs: '0.75rem', sm: '0.875rem' } }}>Discount</Typography>
                                            <Typography variant="h6" fontWeight="bold" sx={{ fontSize: { xs: '1rem', sm: '1.25rem' } }}>{discount}%</Typography>
                                        </>
                                    )}
                                </Paper>
                            </Stack>
                        
                            <Stack direction="row" spacing={2} mt={2}>
                                <Paper elevation={0} sx={{ bgcolor: '#f5f5f5', p: 2, flex: 1 }}>
                                <Typography variant="body2" sx={{ fontSize: { xs: '0.75rem', sm: '0.875rem' } }}>New Total</Typography>
                                <Typography variant="h6" fontWeight="bold" sx={{ fontSize: { xs: '1rem', sm: '1.25rem' } }}>${(amount - (amount * parseFloat(discount) / 100)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Typography>
                                </Paper>
                                <Paper elevation={0} sx={{ bgcolor: '#f5f5f5', p: 2, flex: 1 }}>
                                <Typography variant="body2" sx={{ fontSize: { xs: '0.75rem', sm: '0.875rem' } }}>New Due Date</Typography>
                                <Typography variant="h6" fontWeight="bold" sx={{ fontSize: { xs: '0.875rem', sm: '1.25rem' } }}>{selectedDate?.format('YYYY-MM-DD')}</Typography>
                                </Paper>
                            </Stack>
                            <Stack direction="row" spacing={3} alignItems="center" justifyContent="center" mt={2}>
                                <Button 
                                    variant="contained" 
                                    sx={{width: "100%", background: "#1E61FE", color: "#FFF", stroke: "#1246BE", fontSize: { xs: '0.8rem', sm: '0.875rem' }}}
                                    onClick={() => onConfirming?.()}
                                >
                                    Confirm
                                </Button>
                                <Button variant="outlined" sx={{color: "#1E61FE", border: "1px solid #1E61FE", fontSize: { xs: '0.8rem', sm: '0.875rem' }}} onClick={onClose}>
                                    Cancel
                                </Button>
                            </Stack>
                        </Stack>
                    </DialogContent>
                </>
            ) : (
                <DialogContent>
                    <Stack spacing={3} alignItems="center" textAlign="center">
                        <Typography variant="h5">Great Job!</Typography>
                        <Typography variant="h6">Your Offers Are On Their Way.</Typography>
                        <Typography variant="body1">
                            1 offer was successfully sent, bringing you closer to significant savings.
                            You can now track the status of these offers on the "Offers tracking" screen.
                        </Typography>
                        <Stack direction="row" spacing={2} width="100%">
                            <Button 
                                variant="contained" 
                                sx={{flex: 1, background: "#0B2A72", color: "#FFF"}}
                                onClick={handleSendAnotherOffer}
                            >
                                Send another offer
                            </Button>
                            <Button 
                                variant="outlined" 
                                sx={{flex: 1, color: "#2856BF", border: "1px solid #2856BF"}}
                                onClick={handleGoToOffersTracking}
                            >
                                Go to Offers tracking
                            </Button>
                        </Stack>
                    </Stack>
                </DialogContent>
            )}
        </Dialog>
    );
}