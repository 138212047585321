import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system';

export interface VehiclesWithErrorsProps {
  text: string;
  amount: number;
  isDollars: boolean;
  isPercentage: boolean;
  sx?: SxProps;
}

export function InfoCard({ text, amount, isDollars, isPercentage, sx }: VehiclesWithErrorsProps): React.JSX.Element {
  return (
    <Card sx={{ width: '100%', height: { xs: '100%', sm: '100%' }, alignItems: 'center', ...sx }}>
      <Stack spacing={1}>
        <Stack direction="row" spacing={2} sx={{ alignItems: 'center', justifyContent: 'space-between', pl: 2, pr: 2, pt: { xs: 1, sm: 2 }, pb: { xs: 1, sm: 2 }}}>
            <Typography color="text.secondary" variant="body2" sx={{ ml: 2, alignItems: 'center', textAlign: 'center', fontSize: { xs: '0.75rem', sm: '0.875rem' } }}>
            {text}
            </Typography>
            <Stack direction="row" spacing={0} sx={{ alignItems: 'end', justifyContent: 'space-evenly', mt: 4}}>
                <Typography  sx={{ fontSize: { xs: '1.25rem', sm: '2rem' }, textAlign: 'center',  color: '#1E61FE'}}>{isDollars ? `$${amount.toLocaleString('en-US')}` : amount}</Typography>
                <Typography  sx={{ fontSize: { xs: '0.75rem', sm: '0.875rem' } , textAlign: 'center', color: '#1E61FE', alignItems: 'bottom'}}>{isPercentage ? '%' : !isDollars ? 'days' : ''}</Typography>
            </Stack>
        </Stack>

      </Stack>
    </Card>
  );
}
